import React from "react";
import "../../../../Card.css";
import * as $ from "jquery";
import { Typography } from "@mui/material";

const FlipCardComponent = ({ carousel, title }) => {
  $(document).ready(function ($) {
    //Count nr. of square classNamees
    var countSquare = $(".square").length;

    //For each Square found add BG image
    for (let i = 0; i < countSquare; i++) {
      var firstImage = $(".square").eq([i]);
      var secondImage = $(".square2").eq([i]);

      var getImage = firstImage.attr("data-image");
      var getImage2 = secondImage.attr("data-image");

      firstImage.css("background-image", "url(" + getImage + ")");
      secondImage.css("background-image", "url(" + getImage2 + ")");
    }
  });

  return (
    <div className="square-flip">
      <div className="square">
        <div className="square-container">
          <div className="kallyas-button">
            <Typography
            className={"textshadow"}
              sx={{
                color: "#28282B",
                fontSize: "4.2rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
          </div>
        </div>
        <div className="flip-overlay"></div>
      </div>
      <div className="square2">
        <div className="square-container2">
          <div className="align-center">{carousel}</div>
        </div>
        <div className="flip-overlay"></div>
      </div>
    </div>
  );
};

export default FlipCardComponent;
