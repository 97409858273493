import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import classNames from 'classnames'
import { Element, Link, animateScroll as scroll } from "react-scroll";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Parallax } from 'react-scroll-parallax';
import backgroundImage from './assets/background-snow-1.png';


export default function LandingPage() {

  const [isActive, setIsActive] = useState(false);
  const [progress, setProgress] = useState(0);
  let degree = progress.progress * 360;
  
  const handleClick = () => {
    isActive = true
    console.log("isActive is: " + isActive)
  }
  
  const handleGithubClick = () => {
    window.open("https://github.com/AndreLCanada", '_blank').focus();
  }

  return (
    <Container sx={{transform: "rotateX(180deg)", position: "relative", zIndex: 0,}} >
    <Parallax onProgressChange={(progress) => setProgress(progress)} rotateX={[
            '0deg',
            '360deg'
          ]}>
      <Box >
        <Typography className="text-pop-up-top" sx={{
                justifySelf: "center",
                textAlign: "center",
                fontSize: "12rem",
                fontFamily: "Major Mono Display",
                color: "rgba(245, 245, 245, 0.9)",
              }}>
                Andre
        </Typography>
        <Typography className="text-pop-up-top-no-depth" sx={{
                justifySelf: "center",
                textAlign: "center",
                fontSize: "2.2rem",
                fontFamily: "Lekton",
                color: "rgba(245, 245, 245, 0.9)",
              }}>
                <a className="front-end">Applied-AI Engineer.</a> Software Engineer.
          </Typography>
         
      </Box>
      </Parallax>
      {/*
      <Box sx={{transform: "rotateX(180deg)",}}>
        <Parallax onProgressChange={(progress) => setProgress(progress)} translateX={[
            "150%",
            "-50%"
          ]}>
          <Box sx={{ position: "absolute", zIndex: -1, top: -95, left: 50,}}> <div id = "cloud"></div></Box>
        </Parallax>
        <Parallax onProgressChange={(progress) => setProgress(progress)} translateX={[
            "-150%",
            "50%"
          ]}>
          <Box sx={{ position: "absolute", zIndex: 2, top: -95, left: 892,}}> <div id = "cloud-2"></div></Box>
        </Parallax>
        <Parallax onProgressChange={(progress) => setProgress(progress)} translateX={[
            "170%",
            "-70%"
          ]}>  
        <Box sx={{ position: "absolute", zIndex: -1, top: 390, left: 192,}}> <div id = "cloud-3"></div></Box>
       </Parallax>
      </Box>*/}
      <Parallax speed={22} onProgressChange={(progress) => setProgress(progress)} translateY={[
          '180%',
          '-700%'
        ]}>
            <Box sx={{position: "absolute", zIndex: 3, right: "45%", bottom: "45vh", }} onClick={handleClick} >
             <Box onClick={handleClick} >
              <Link
                    to="about"
                    smooth={true}
                    duration={2000}
                    spy={true}
                    exact="true"
                    onClick={() => {
                    setIsActive(true);
                }}
                  >
            <KeyboardDoubleArrowUpIcon sx={{ fill: "rgba(245, 245, 245, 0.8)", fontSize: 140,  }} className={classNames("text-pop-up-top-scroll-arrow", "scroll-arrow", {'rotate-scale-down': isActive})} />
               </Link>
             </Box>
            </Box>
            </Parallax> 
    </Container>
  
  
  );
};


