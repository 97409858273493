import { Box, Typography, Container, Paper } from "@mui/material";
import React from "react";
import GitHubCalendar from 'github-calendar';
import '../App.css';

export default function GithubHeatmap() {
   
    new GitHubCalendar('.calendar', `andrelcanada`, { responsive: true, tooltips: true, })


    return (
       <Container sx={{ margin: "1.3rem 0rem 1.8rem 0rem",}}>
        <Paper 
        sx={{ 
            bgcolor: "#25262a",
            borderRadius: "50px",
            width: "auto",
            marginX: "auto",
            paddingX: 2,
            }}
        >
          <div className="calendar"></div>
         </Paper>
       </Container>

    );
}