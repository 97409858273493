import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { ParallaxProvider } from 'react-scroll-parallax';
import { StyledEngineProvider } from '@mui/material/styles';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <ParallaxProvider>
      <App width="100vw"/>
    </ParallaxProvider>
  </StyledEngineProvider>
  </React.StrictMode>
);


