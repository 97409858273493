import React from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const UhOh = () => {
  return (
    <Paper 
      sx={{ 
        width: "100vw",
        backgroundColor: "#f7f6f6",
        height: "auto",
        position: "relative",
        zIndex: 12,
        display: "flex",
        flexDirection: 'column',
        }}>
        <Typography
          className='flip-horizontal-fwd'
          sx={{ 
              color: "#28282B",
              marginX: "10vw",
              marginY: "4vw",
              fontSize: '12vw',
              fontFamily: "Lekton",
              textAlign: "center",
                        }}> Uh Oh! </Typography> 
        <Typography
          className='flip-horizontal-fwd'
          sx={{ 
              color: "#28282B",
              marginX: "10vw",
              marginY: "4vw", 
              fontSize: '5vw',
              fontFamily: "Lekton",
              textAlign: "center",
                        }}>
                It looks like the screen is getting a little small... </Typography>
       <Typography
          className='flip-horizontal-fwd'
          sx={{ 
              color: "#28282B",
              marginX: "10vw",
              marginY: "4vw", 
              fontSize: '4vw',
              fontFamily: "Lekton",
              textAlign: "center",
                        }}> 
                        The mobile/tablet-responsive version of this site is coming soon! If you are curious to see some of my work with mobile-responsive applications, check out <a className={"cdlink"} href="https://www.codesnippets.app">codeSnippets</a>! 
            </Typography>
       </Paper>
  )
}

export default UhOh