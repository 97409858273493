import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import htmlPDF from '../certificate-assets/html-cert-andre-1.png';
import CloseIcon from '@mui/icons-material/Close';

const HTMLModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    height: "auto",
    bgcolor: "rgba(245, 245, 245, 0.98)",
    border: '2px solid #000',
    py: 1,
    px: 1,
  };
  
  return (
    <React.Fragment >
      <Button onClick={handleOpen} sx={{ ml: 4.5, mt: 4, }} ><Typography sx={{ fontFamily: "Lekton", fontSize: "1.4rem", }}>HTML</Typography></Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style}}>
          <img src={htmlPDF}  className="js-button" />
          <Button sx={{ position: "absolute", }} onClick={handleClose}><CloseIcon sx={{
                color: "rgba(245, 245, 245, 0.9)",
                p: 3,
                fontSize: '3rem',
              }} /></Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default HTMLModal