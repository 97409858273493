import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { Parallax } from 'react-scroll-parallax';
import Typewriter from 'typewriter-effect';
import {
  CircularGauge, Scale, Title, Font, Margin,
} from 'devextreme-react/circular-gauge';
import Divider from '@mui/material/Divider';
import { auto, Instance } from '@popperjs/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from '@mui/material/Modal';
import { Element, Link, animateScroll as scroll } from "react-scroll";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WorkIcon from '@mui/icons-material/Work';
import SaveIcon from '@mui/icons-material/Save';
import HandymanIcon from '@mui/icons-material/Handyman';
import GitHubIcon from '@mui/icons-material/GitHub';
import SchoolIcon from '@mui/icons-material/School';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RecommendIcon from '@mui/icons-material/Recommend';
import MailIcon from '@mui/icons-material/Mail';
import MobileResponsiveExample from './components/mobileResponsiveExample';
import WebResponsiveExample from './components/webResponsiveExample';
import nextjsImage from './images/nextjsLogo.png'
import reactImage from './images/reactLogo.png'
import javascriptImage from './images/javascriptLogo.png'
import htmlImage from './images/htmlLogo.png'
import cssImage from './images/cssLogo.webp'
import muiImage from './images/muiLogo.png'
import airtableImage from './images/airtableLogo.png'
import vercelImage from './images/vercelLogo.png'
import codeSnippetImage from './images/codeSnippetsImage.png'

const actions = [
  { icon: 
   <a href="#about"><EmojiPeopleIcon sx={{ color: "#28282B",}} />
   </a>, name: 'about' 
   },
   { icon: 
     <a href="#skills"><HandymanIcon sx={{ color: "#28282B",}} />
     </a>, name: 'skills' 
     },
     { icon: 
       <a href="#github"><GitHubIcon sx={{ color: "#28282B",}} />
       </a>, name: 'github' 
       },
       { icon: 
         <a href="#experience"><SchoolIcon sx={{ color: "#28282B",}} />
         </a>, name: 'experience' 
         },
         { icon: 
           <a href="#portfolio"><WorkIcon sx={{ color: "#28282B",}}  />
           </a>, name: 'portfolio' 
           },
           { icon: 
             <a href="#testimonials"><RecommendIcon sx={{ color: "#28282B",}} />
             </a>, name: 'testimonials' 
             },
             { icon: 
               <a href="#contact"><MailIcon sx={{ color: "#28282B",}} />
               </a>, name: 'contact' 
               },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PortfolioExample() {
  const [progress, setProgress] = useState(0);
  const [booleanToggle, setBooleanToggle] = useState(false);
  const isMobileWidth = useMediaQuery("(max-width:600px)");
  const positionRef = React.useRef({
      x: 0,
      y: 0,
    });
  
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);
  
  const handleMouseMove = (event) => {
      positionRef.current = { x: event.clientX, y: event.clientY };
  
      if (popperRef.current !== null) {
        popperRef.current.update();
      }
    };
  
    
  
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handlePreviewClick = () => {
      window.open("https://github.com/AndreLCanada", '_blank').focus();
    }
  

  return (
    <Container>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Paper 
      maxWidth={true}
      sx={{ 
        positon: "relative",
        zIndex: 3,
        pb: 44,
        width: "100vw",
        backgroundColor: "#f7f6f6", /*4ABDAC*/
        height: "auto",
        borderRadius: "100px",
        }}>
         <SpeedDial
        ariaLabel="SpeedDial Menu"
        direction="down"
        sx={{ position: 'absolute', zIndex: 12, left: 136, top: 160, width: "2rem", }}
        icon={<ChangeHistoryIcon className="pulse-button" openIcon={<ChangeHistoryIcon />} sx={{color: "#28282B", fontSize: '3rem',  }} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
      <Box id="portfolio" sx={{ position: 'absolute', top: 88, }}> </Box>
        <Typography
          className='flip-horizontal-fwd'
          sx={{ 
              color: "#28282B",
              pt: 10, 
              fontSize: '7rem',
              fontFamily: "Lekton",
              textAlign: "left",
              paddingLeft: 22,
                        }}>
            portfolio
          </Typography>
          
          <Parallax 
          onProgressChange={(progress) => setProgress(progress)} 
          translateX={[
            '-70%',
            '30%'
            ]}
            easing={"easeOutCubic"}>
            
            
            </Parallax>
            <Parallax 
          onProgressChange={(progress) => setProgress(progress)} 
          translateX={[
            '100%',
            '7%'
            ]}
            easing={"easeOutCubic"}>
            
          <Paper
            elevation={6}
            sx={{ 
              width: "60vw",
              backgroundColor: "#f7f6f6",
              height: "auto",
              borderRadius: "100px 100px",
              paddingX: 4,
              paddingY: 4,
              mt: 8,
              }}>
              <Box>
                  <Paper 
                  elevation={6}
                  className="pp_container" 
                  sx={{ 
                    position: "relative", 
                    zIndex: 8, 
                    width: "40vw", 
                    height: "auto", 
                    backgroundColor: "black",
                    borderRadius: "50px 50px",
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center",
                    marginX: "auto",
                    mt: 3,
                    mb: 2,
                    p: 6,
                  }}>
                  
                    <Box className="overlay" sx={{ position: "absolute", zIndex: 4, width: "100%", height: "100%", backgroundColor: "black", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center",}}>
                      <Typography
                          sx={{
                        color: "rgba(245, 245, 245, 0.9)",
                        fontSize: '18rem',
                        textAlign: "center",
                        paddingY: "auto",
                        fontFamily: "Lekton",
                        position: "absolute",
                        m: 2,
                        }}>
                        cS
                      </Typography>
                    </Box>
                
          
                <Box className="info_container">
                  {/* <Typography sx={{textAlign: "center", fontFamily: "Lekton", fontSize: "3rem", marginTop: "1rem", color: "white",}}>
                    codeSnippets
                  </Typography> */}
                
                  <img src={codeSnippetImage} className="csImage"  />

                  <Stack sx={{ display: "flex", justifyContent: "space-evenly", marginX: "2vw", marginRight: '2vw', marginTop: ".5vw",}} direction="row">
                    <img src={nextjsImage} className="techStack nextjslogo"  />
                    <img src={reactImage} className="techStack"  />
                    <img src={javascriptImage} className="techStack"  />
                    <img src={cssImage} className="techStack"  />
                    </Stack>
                  <Stack sx={{ display: "flex", justifyContent: "space-evenly", marginTop: "1vw", marginX: "2vw", marginLeft: "3.5vw",}} direction="row">
                    <img src={htmlImage} className="techStack"  />
                    <img src={airtableImage} className="techStack airtablelogo"  />
                    <img src={muiImage} className="techStack"  />
                    <img src={vercelImage} className="techStack vercellogo"  />
                  </Stack>
                  <Typography  sx={{
                  position: "relative", 
                  zIndex: 2, 
                  color: "rgba(245, 245, 245, 0.9)",
                  fontSize: '1.2rem',
                  textAlign: "center",
                  fontFamily: "Lekton",
                  margin: "1rem",
                  width: "90%",
                  marginX: "auto",
                  }}>
                    An open-source code-snippet library tool that allows any dev to clone, deploy, and enjoy! 
                  </Typography>
                  <Typography  sx={{
                  position: "relative", 
                  zIndex: 2, 
                  color: "rgba(245, 245, 245, 0.9)",
                  fontSize: '1.2rem',
                  textAlign: "center",
                  fontFamily: "Lekton",
                  width: "90%",
                  marginX: "auto",
                  }}>
                    <a className="links" href="https://codesnippets.app" target="_blank">Website</a> | <a className="links" href="https://github.com/AndreLCanada/code-snippets" target="_blank">Code</a>
                  </Typography>

                  </Box>
                 </Paper>
                 </Box>
              </Paper>
            </Parallax>    
      </Paper>
    </Container>
  )
}