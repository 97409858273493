import { Stack } from '@mui/system'
import React from 'react'

import { Box, Typography } from '@mui/material'

const SkillsComponent = ({title, image, extraClass}) => {
  return (
    <Box sx={{ width: "6vw", height: "auto", p: "1vw",}}>

      <Stack direction="column" sx={{display: "flex", justifyContent: "center",}}>
        <img src={image} className={`techStackSkills ${extraClass}`}  />
        <Typography sx={{ color: "white", pt: "1vw",}}>
          {title}
        </Typography>
      </Stack>
    </Box>
  )
}

export default SkillsComponent