import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Parallax } from "react-scroll-parallax";
import useMediaQuery from "@mui/material/useMediaQuery";
import CodeIcon from "@mui/icons-material/Code";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import HandymanIcon from "@mui/icons-material/Handyman";
import GitHubIcon from "@mui/icons-material/GitHub";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";
import ToolsCarosel from "./carosels/toolsCarosel";
import FrameworksCarosel from "./carosels/frameworksCarosel";
import LanguagesCarosel from "./carosels/languagesCarosel";
import Carousel from "nuka-carousel";
import FlipCardComponent from "./flip-card";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import ConstructionIcon from "@mui/icons-material/Construction";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
const actions = [
  {
    icon: (
      <a href="#about">
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "about",
  },
  {
    icon: (
      <a href="#skills">
        <HandymanIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "skills",
  },
  {
    icon: (
      <a href="#github">
        <GitHubIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "github",
  },
  {
    icon: (
      <a href="#experience">
        <SchoolIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "experience",
  },
  {
    icon: (
      <a href="#portfolio">
        <WorkIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <a href="#testimonials">
        <RecommendIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <a href="#contact">
        <MailIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "contact",
  },
];

const Skills = () => {
  const isMobileWidth = useMediaQuery("(max-width:600px)");
  const [progress, setProgress] = useState(0);

  /*  const handleGithubClick = () => {
    window.open("https://github.com/AndreLCanada", '_blank').focus();
  } */

  return (
    <Paper
      sx={{
        width: "100vw",
        backgroundColor: "rgb(83, 148, 189)",
        height: "auto",
        borderRadius: "100px 100px 0px 0px",
        pb: 40,
        position: "relative",
        zIndex: 12,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial Menu"
        direction="down"
        sx={{ position: "absolute", zIndex: 12, right: 120, top: 165, width: "2rem" }}
        icon={
          <ChangeHistoryIcon
            className="pulse-button"
            openIcon={<ChangeHistoryIcon />}
            sx={{ color: "rgba(245, 245, 245, 0.9)", fontSize: "3rem" }}
          />
        }
      >
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
        ))}
      </SpeedDial>
      <Box id="skills" sx={{ position: "absolute", top: 88 }}>
        {" "}
      </Box>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Typography
            className="flip-horizontal-fwd"
            sx={{
              color: "rgba(245, 245, 245, 0.9)",
              fontSize: "7rem",
              fontFamily: "Lekton",
              textAlign: "right",
              paddingRight: 22,
            }}
          >
            skills
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ height: "auto", mt: -6 }}>
          <Parallax
            onProgressChange={(progress) => setProgress(progress)}
            translateX={["-100%", "14%"]}
            easing={"easeOutCubic"}
          >
            <Paper
              elevation={6}
              className="we_container"
              sx={{
                position: "relative",
                width: "80vw",
                backgroundColor: "#f7f6f6",
                height: "auto",
                borderRadius: "100px 100px",
                paddingY: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginX: "auto",
                mt: 3,
                mb: 2,
                p: 3,
              }}
            >
              {/* <Box
                    className="overlayWorkExp"
                    sx={{
                      position: "absolute",
                      zIndex: 4,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white",
                      borderRadius: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        paddingY: "auto",
                        position: "absolute",
                        m: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#28282B",
                          pt: 2,
                          fontSize: "4.2rem",
                          fontFamily: "Lekton",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Software Engineering
                      </Typography>
                      <CodeIcon
                        sx={{
                          color: "rgba(245, 245, 245, 0.9)",
                          p: 4,
                          mt: 6,
                          fontSize: "4.2rem",
                          marginX: "auto",
                          borderRadius: "50%",
                          backgroundColor: "#28282B",
                        }}
                      />
                    </Box>
                  </Box> */}

              <Box className="info_container">
                <Box sx={{ width: "65vw" }}>
                  <Carousel
                    enableKeyboardControls={true}
                    renderCenterLeftControls={null}
                    renderCenterRightControls={null}
                    animation={"zoom"}
                    zoomScale={"0.35"}
                    speed={777}
                  >
                    <FlipCardComponent carousel={<LanguagesCarosel className="textshadow" />} title={"Languages"} />
                    <FlipCardComponent carousel={<FrameworksCarosel className="textshadow" />} title={"Frameworks"} />
                    <FlipCardComponent carousel={<ToolsCarosel className="textshadow" />} title={"Tools"} />
                  </Carousel>
                </Box>
              </Box>
            </Paper>
          </Parallax>
        </Grid>
        {/* <Grid item xs={12} sx={{ height: "auto", mt: 16 }}>
          <Parallax
            onProgressChange={(progress) => setProgress(progress)}
            translateX={["100%", "-30%"]}
            easing={"easeOutCubic"}
          >
            <Paper
              elevation={6}
              sx={{
                display: "flex",
                width: "75vw",
                backgroundColor: "rgba(245, 245, 245, 0.1)",
                height: "auto",
                borderRadius: "100px",
                marginX: "auto",
                alignItems: "center",
                pb: 8,
                mb: 1,
                textAlign: "center",
              }}
            >
              <Stack direction="column" sx={{ display: "flex", width: "75vw", transform: "translateY(-55px)" }}>
                <DesignServicesIcon
                  sx={{
                    color: "rgba(245, 245, 245, 0.9)",
                    fontSize: "2rem",
                    padding: 3,
                    marginX: "auto",
                    borderRadius: "50%",
                    backgroundColor: "#28282B",
                  }}
                />
                <Typography
                  sx={{
                    color: "#28282B",
                    pt: 2,
                    fontSize: "2.4rem",
                    fontFamily: "Lekton",
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  UI/UX Design
                </Typography>
                <Typography
                  sx={{
                    color: "#28282B",
                    pt: 2,
                    pb: 2,
                    fontSize: "1.2rem",
                    fontFamily: "Lekton",
                    width: "28rem",
                    marginX: "auto",
                  }}
                >
                  I recognise the influence of elegant, thoughtful, and clean design.
                </Typography>

                <Stack direction="row" sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
                  <Stack direction="column" sx={{ display: "flex", justifyItems: "center" }}>
                    <Typography
                      sx={{
                        color: "#FFAD05",
                        fontSize: "1.4rem",
                        fontFamily: "Lekton",
                        width: "auto",
                        marginX: "auto",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      Things I design:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#28282B",
                        pt: 1,
                        fontSize: "1.2rem",
                        fontFamily: "Lekton",
                        width: "22rem",
                        marginX: "auto",
                        textAlign: "center",
                      }}
                    >
                      Responsive Web Apps, Mobile Apps, UI, UX
                    </Typography>
                  </Stack>

                  <Stack direction="column" sx={{ display: "flex", justifyItems: "center" }}>
                    <Typography
                      sx={{
                        color: "#FFAD05",
                        fontSize: "1.4rem",
                        fontFamily: "Lekton",
                        width: "auto",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      Design Tools:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#28282B",
                        pt: 1,
                        fontSize: "1.2rem",
                        fontFamily: "Lekton",
                        width: "22rem",
                        marginX: "auto",
                        textAlign: "center",
                        display: "flex",
                        justifySelf: "center",
                      }}
                    >
                      Figma, Material UI, Canva, Adobe Color, AdobeXD
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Parallax> 
        </Grid>*/}
      </Grid>
    </Paper>
  );
};

export default Skills;
