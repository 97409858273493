import React from "react";
import javascriptImage from "../../../../portfolio/images/javascriptLogo.png";
import pythonImage from "../../../../portfolio/images/python-logo.png";
import htmlImage from "../../../../portfolio/images/htmlLogo.png";
import cssImage from "../../../../portfolio/images/cssLogo.webp";
import gitImage from "../../../../portfolio/images/gitLogo.png";
import typescriptImage from "../../../../portfolio/images/typescriptLogo.png";
import graphqlImage from "../../../../portfolio/images/graphqlImage.png";
import jsonImage from "../../../../portfolio/images/jsonLogo.png";
import sassImage from "../../../../portfolio/images/sassImage.png";
import SkillsComponent from "../../skills-component";

const LanguagesCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent image={htmlImage} title={"HTML5"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={cssImage} title={"CSS3"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={sassImage} title={"SASS/SCSS"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={javascriptImage} title={"JavaScript"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={typescriptImage} title={"TypeScript"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={gitImage} title={"Git"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={jsonImage} title={"JSON"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={graphqlImage} title={"GraphQL"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={pythonImage} title={"Python"} extraClass={"solidity"} />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default LanguagesCarosel;
