import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Parallax } from "react-scroll-parallax";
import { Element, Link, animateScroll as scroll } from "react-scroll";
import CodecademyImage from "../assets/code-academy-e1590056964242-modified.png";
import UdemyImage from "../assets/logos-removebg-preview.png";
import GoogleImage from "../assets/google-pngrepo-com.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import GithubHeatmap from "../../githubPreview";
import { Instance } from "@popperjs/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import WorkIcon from "@mui/icons-material/Work";
import SaveIcon from "@mui/icons-material/Save";
import HandymanIcon from "@mui/icons-material/Handyman";
import GitHubIcon from "@mui/icons-material/GitHub";
import SchoolIcon from "@mui/icons-material/School";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import RecommendIcon from "@mui/icons-material/Recommend";
import MailIcon from "@mui/icons-material/Mail";

const actions = [
  {
    icon: (
      <a href="#about">
        <EmojiPeopleIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "about",
  },
  {
    icon: (
      <a href="#skills">
        <HandymanIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "skills",
  },
  {
    icon: (
      <a href="#github">
        <GitHubIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "github",
  },
  {
    icon: (
      <a href="#experience">
        <SchoolIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "experience",
  },
  {
    icon: (
      <a href="#portfolio">
        <WorkIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "portfolio",
  },
  {
    icon: (
      <a href="#testimonials">
        <RecommendIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "testimonials",
  },
  {
    icon: (
      <a href="#contact">
        <MailIcon sx={{ color: "#28282B" }} />
      </a>
    ),
    name: "contact",
  },
];

const Github = () => {
  const isMobileWidth = useMediaQuery("(max-width:600px)");
  const [progress, setProgress] = useState(0);
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });

  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current !== null) {
      popperRef.current.update();
    }
  };

  const handleGithubClick = () => {
    window.open("https://github.com/AndreLCanada", "_blank").focus();
  };

  return (
    <Paper
      sx={{
        width: "100vw",
        backgroundColor: "#f7f6f6",
        height: "auto",
        borderRadius: "100px 100px 0px 0px",
        pb: 40,
        position: "relative",
        zIndex: 12,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial Menu"
        direction="down"
        sx={{ position: "absolute", zIndex: 12, left: 120, top: 165, width: "2rem" }}
        icon={
          <ChangeHistoryIcon
            className="pulse-button"
            openIcon={<ChangeHistoryIcon />}
            sx={{ color: "#28282B", fontSize: "3rem" }}
          />
        }
      >
        {actions.map((action) => (
          <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
        ))}
      </SpeedDial>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Typography
            id="github"
            className="flip-horizontal-fwd"
            sx={{
              color: "#28282B",
              fontSize: "7rem",
              fontFamily: "Lekton",
              textAlign: "left",
              paddingLeft: 22,
            }}
          >
            github
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Parallax
            onProgressChange={(progress) => setProgress(progress)}
            translateX={["100%", "-30%"]}
            easing={"easeOutCubic"}
          >
            <Tooltip
              title="Click to view my Github profile!"
              placement="top"
              arrow
              PopperProps={{
                popperRef,
                anchorEl: {
                  getBoundingClientRect: () => {
                    return new DOMRect(positionRef.current.x, areaRef.current.getBoundingClientRect().y, 0, 0);
                  },
                },
              }}
            >
              <Paper
                ref={areaRef}
                onMouseMove={handleMouseMove}
                elevation={6}
                className="github-paper"
                onClick={handleGithubClick}
                sx={{
                  width: "60vw",
                  backgroundColor: "rgba(245, 245, 245, 0.1)",
                  height: "auto",
                  borderRadius: "100px 100px",
                  marginLeft: 55,
                  paddingX: 6,
                  paddingY: 6,
                  transform: "translateY(-45px)",
                }}
              >
                <GithubHeatmap />
              </Paper>
            </Tooltip>
          </Parallax>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Github;
