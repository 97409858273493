import React from "react";
import airtableImage from "../../../../portfolio/images/airtableLogo.png";
import firebaseImage from "../../../../portfolio/images/firebase-logo.png";
import colabImage from "../../../../portfolio/images/colab-logo.png";
import githubImage from "../../../../portfolio/images/githubLogo.png";
import gitpodImage from "../../../../portfolio/images/gitpodLogo.png";
import copilotImage from "../../../../portfolio/images/copilot-logo.png";
import vscodeImage from "../../../../portfolio/images/vscodeLogo.png";
import openaiImage from "../../../../portfolio/images/openai-image.png";
import huggingfaceImage from "../../../../portfolio/images/huggingface-logo.png";
import SkillsComponent from "../../skills-component";

const ToolsCarosel = () => {
  return (
    <section id="slideshow">
      <div class="entire-content">
        <div class="content-carrousel">
          <figure class="shadow">
            <SkillsComponent image={openaiImage} title={"OpenAI"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={copilotImage} title={"Github Copilot"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={githubImage} title={"Github"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={gitpodImage} title={"Gitpod"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={vscodeImage} title={"VS Code"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={colabImage} title={"Google Colab"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={airtableImage} title={"Airtable"} extraClass={null} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={firebaseImage} title={"Firebase"} extraClass={"vercellogo"} />
          </figure>
          <figure class="shadow">
            <SkillsComponent image={huggingfaceImage} title={"HuggingFace"} extraClass={null} />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default ToolsCarosel;
