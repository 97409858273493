import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { Parallax } from "react-scroll-parallax";
import Typewriter from "typewriter-effect";
import { Element, Link, animateScroll as scroll } from "react-scroll";
import { Center } from "devextreme-react/map";
import recPhoto from "../media/recImage.jpeg";
export default function RecommendationCardHunter() {
  const [progress, setProgress] = useState(0);
  const [booleanToggle, setBooleanToggle] = useState(false);
  let degree = progress * 360;
  console.log(`Progress on Intro page:` + progress);

  const handleHunterRecRedirect = () => {};

  const handleHunterLinkedinRedirect = () => {};

  return (
    <Container sx={{ marginX: "auto" }}>
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "55vw",
          backgroundColor: "smokewhite",
          height: "400px",
          borderRadius: "50px",
          marginX: "auto",
          mt: 5,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignSelf: "center",
            width: "75%",
          }}
        >
          <Box
            sx={{
              marginX: "auto",
              padding: "2vw 2.5vw",
             // boxShadow: "inset -7px 0px 12px -8px rgba(0,0,0,0.3), inset 7px 0px 12px -8px rgba(0,0,0,0.3)",
              //borderTopLeftRadius: "100px",
              //borderBottomRightRadius: "100px",
              //borderBottomLeftRadius: "20px",
              //borderTopRightRadius: "20px",
              //width: "30vw",
            }}
          >
            <Avatar
              onClick={handleHunterLinkedinRedirect}
              alt="Remy Sharp"
              src={recPhoto}
              sx={{ width: "10vw", height: "auto", marginX: "auto" }}
            />
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1.4rem",
                fontFamily: "Lekton",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Hunter Hodnett
            </Typography>
            <Typography
              sx={{
                color: "#28282B",
                pt: 1,
                fontSize: "1rem",
                fontFamily: "Lekton",
                textAlign: "center",
                marginX: "auto",
                fontWeight: "bold",
              }}
            >
              Lead, Learning Platform at Proof of Learn
            </Typography>
          </Box>
          <Typography
            onClick={handleHunterRecRedirect}
            sx={{
              color: "#28282B",
              paddingTop: "1rem",
              fontSize: "1.2rem",
              fontFamily: "Lekton",
              textAlign: "center",
              marginX: "auto",
            }}
          >
            "He’s a gem of an engineer that any organization would be lucky to have."
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}
