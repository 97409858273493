import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import mobileVideo from '../videos/Mobile-trimmed-preview.mp4';

export default function MobileResponsiveExample() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const runFunction = () => {
    setTimeout(document.getElementById('myMobileVideo').play(), 500);
  };
    const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    height: "auto",
    bgcolor: "rgba(245, 245, 245, 0.98)",
    border: '2px solid #000',
    py: 1,
    px: 1,
  };
  
  return (
    <React.Fragment >
      <Button onClick={handleOpen} sx={{ ml: 3.5, mt: 4,
                borderRadius: "100px", }} ><Typography
              sx={{
                color: "rgb(83, 148, 189)",
                fontSize: '1.2rem',
                textAlign: "center",
                fontFamily: "Lekton",
                fontWeight: "bold",
              }}>
               mobile-responsive example work
              </Typography></Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style}}>
              <video id="myMobileVideo" width="300vw" height="auto" controls  >
                <source src={mobileVideo} type="video/mp4"  />
                Your browser does not support the video tag.
              </video>
              
          <Button sx={{ position: "absolute", }} onClick={handleClose}><CloseIcon sx={{
                color: "black",
                backgroundColor: "rgba(245, 245, 245, 0.9)",
                p: 3,
                borderRadius: "50px",
                fontSize: '3rem',
              }} /></Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}


